<template>
  <v-card :dark="dark">
    <div>
      <v-toolbar color="color2 color2Text--text" dense>
        <v-toolbar-title>Live Scoring{{`${showSwitch ? ' - Time To Switch' : gameText}`}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="color2Text" text icon @click.stop="closeMe(false)" :disabled="loading">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
    </div>
    <v-card-text class="pa-0">
      <v-window v-model="window" touchless>
        <v-window-item :value="0">
          <v-container class="pt-3">
            <v-row dense>
              <v-col cols="12" class="title">
                Want to score this match?
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Your name"
                  v-model="name"
                  :disabled="!!user"
                  persistent-hint color="color3"
                  hint="Please enter your full name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  label="Your role"
                  :items="roles"
                  v-model="role"
                  persistent-hint
                  color="color3"
                  item-color="color3"
                  hint="Are you reffing or just keeping score for fun?"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  label="Game"
                  :items="matchIn.games.map(m => {return {text: m.number , value: m.number - 1}})"
                  v-model="gameI"
                  persistent-hint
                  color="color3"
                  item-color="color3"
                  hint="What game do you want to start with?"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn
                  color="color3"
                  text
                  :disabled="!name"
                  @click.stop="start"
                >
                  start scoring
                </v-btn>
                <v-btn
                  color="color3 color3Text--text"
                  fab
                  :disabled="!name"
                  :loading="loading"
                  @click.stop="start"
                >
                  <v-icon>fas fa-caret-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-window-item>
        <!-- scoring screen -->
       <v-window-item :value="1">
          <v-container fluid v-if="liveMatch" class="pb-0">
            <v-window v-model="gameI" touchless>
              <v-window-item v-for="(game, i) in match.games" :key="i" :value="i">
                <v-row dense>
                  <v-col cols="12" class="py-0 mb-6">
                    <team-card
                      :match="match"
                      :home="true"
                      :switchSides="switchSides"
                      :gameI="i"
                      :dark="dark"
                      :orientation="orientation"
                      :division="division"
                    ></team-card>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <team-card
                      :match="match"
                      :home="false"
                      :switchSides="switchSides"
                      :gameI="i"
                      :dark="dark"
                      :orientation="orientation"
                      :division="division"
                    ></team-card>
                  </v-col>
                  <div class="pa-0 text-center vs d-flex justify-center align-center">
                    <v-avatar
                      :color="dark ? 'color1Text color1--text' : 'color1 color1Text--text'"
                      size="48"
                      class="elevation-4 title"
                      style="z-index: 99999"
                    >
                      vs
                    </v-avatar>
                  </div>
                  <div :class="`pa-0 text-center vsHome${orientation !== 'landscape' ? '2' : ''} d-flex justify-center align-center`" v-if="homeLogo">
                    <v-img
                      v-if="homeLogo"
                      :src="homeLogo"
                      max-height="48"
                      width="48"
                      contain
                    ></v-img>
                  </div>
                  <div :class="`pa-0 text-center vsAway${orientation !== 'landscape' ? '2' : ''} d-flex justify-center align-center`" v-if="awayLogo">
                    <v-img
                      v-if="awayLogo"
                      :src="awayLogo"
                      max-height="48"
                      width="48"
                      contain
                    ></v-img>
                  </div>
                </v-row>
              </v-window-item>
            </v-window>
          </v-container>
        </v-window-item>
        <!-- exit screen -->
        <v-window-item :value="2">
          <v-card flat>
            <v-card-text class="title text-center">
              Would you like to save and report this score?
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                color="success"
                @click.stop="saveEdit(true)"
                :loading="loading"
              >yes</v-btn>
              <v-btn
                color="error"
                @click.stop="closeMe(true)"
                :disabled="loading"
              >no</v-btn>
            </v-card-actions>
            <v-card-actions class="justify-center">
              <v-btn color="color3" text @click.stop="window = 1" :disabled="loading">oops, go back</v-btn>
            </v-card-actions>
          </v-card>
        </v-window-item>
      </v-window>
      <div class="text-center" v-if="currentGame && window === 1"><i>Playing to {{currentGame.to}}{{currentGame.cap ? ` capped at ${currentGame.cap}` : ' with no cap'}}</i></div>
      <div class="px-3">
        <v-alert type="info" :value="showSwitch" text transition="scale-transition">
          Time to switch
        </v-alert>
        <v-alert
          type="success"
          :value="currentGame && currentGame.status === 'Complete'"
          text
          prominent
          transition="scale-transition">
          Set Over
          <v-btn
            class="ml-3"
            color="success white--text"
            @click.stop="saveEdit(true)"
            v-if="window === 1 && match.complete"
            :loading="loading"
          >
            Save & Close
          </v-btn>
          <v-btn
            class="ml-3"
            color="success white--text"
            @click.stop="nextClick"
            v-if="window === 1 && !match.complete"
          >
            {{dirty ? 'Save & ' : ''}}Go to set {{currentGame && currentGame.number + 1}}
          </v-btn>
        </v-alert>
      </div>
    <div class="fabs pa-2 text-center">
      <v-btn
        v-if="false"
        color="success"
        text fixed
        bottom left
        @click.stop="gameI--"
      >text</v-btn>
      <v-btn
        color="color3 color3Text--text"
        small fab
        @click.stop="dark = !dark"
      >
        <v-icon>fa{{dark ? 'r' : 's'}} fa-sun</v-icon>
      </v-btn>
      <v-btn
        v-if="window === 1"
        :color="showSwitch ? 'success white--text' : 'color3 color3Text--text'"
        small fab
        @click.stop="switchSides = !switchSides"
        class="ml-2"
      >
        <v-icon>fas fa-repeat-alt</v-icon>
      </v-btn>
      <v-btn
        :color="'color3 color3Text--text'"
        small fab
        @click.stop="startTO"
        class="ml-2"
      >
        <v-icon>fas fa-whistle</v-icon>
      </v-btn>
      <v-btn
        v-if="window === 1 && currentGame && currentGame.status"
        :color="'color3 color3Text--text'"
        small fab
        @click.stop="saveClick"
        :disabled="!dirty"
        :loading="loading"
        class="ml-2"
      >
        <v-icon>fas fa-save</v-icon>
      </v-btn>
    </div>
    </v-card-text>
    <v-snackbar
      v-if="false"
      v-model="showSwitch"
      vertical
      color="warning"
      :timeout="0"
      class="text-center black--text title"
    >
      Time to Switch Sides
      <v-btn @click.native="showSwitch = false" text>Close</v-btn>
      <v-btn @click.native="showSwitch = false" text>Close</v-btn>
    </v-snackbar>
    <v-overlay :value="showTO" :z-index="100000" :opacity=".6">
      <countdown :lengthInSeconds="toLength"></countdown>
      <div class="text-center mt-13">
        <v-btn color="color3Text color3--text" @click.stop="showTO = false">
          Time in
        </v-btn>
      </div>
    </v-overlay>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import RouteMixin from '@/Mixins/RouteMixin'
import TeamCard from '@/components/Tournament/LiveScoring/TeamCard.vue'
import Countdown from '@/components/Utils/Countdown'
import Match from '@/classes/Match'
import * as mutations from '@/store/MutationTypes'
import debounce from 'lodash.debounce'
import { routeHelper } from '@/classes/HelperFunctions'

export default {
  props: ['orientation', 'matchIn', 'active', 'round', 'division'],
  mixins: [RouteMixin],
  data () {
    return {
      dark: false,
      window: 0,
      gameI: 0,
      currentTab: null,
      role: 'ref',
      roles: [{ text: 'I am reffing', value: 'ref' }, { text: 'I am a spectator', value: 'spec' }],
      loading: false,
      liveMatch: null,
      switchSides: false,
      showSwitch: false,
      oDto: null,
      name: null,
      showTO: false
    }
  },
  computed: {
    ...mapGetters(['user', 'matchProps', 'isLiveScoring', 'tournament', 'getTeam']),
    useLogos () {
      return this.division && this.division.useLogos
    },
    toLength () {
      const to = this.tournament.props.find(f => f.startsWith('to-'))
      return to ? +to.replace('to-', '') : 60
    },
    match () {
      return this.liveMatch && this.liveMatch.match
    },
    currentGame () {
      return this.match && this.match.games[this.gameI]
    },
    gameOver () {
      return this.currentGame && !!this.currentGame.winner
    },
    gameText () {
      return this.currentGame ? ` - Game ${this.currentGame.number}` : ''
    },
    switchOn () {
      return this.currentGame ? this.currentGame.to === 15 ? 5 : this.currentGame.to === 11 ? 4 : 7 : 7
    },
    switch () {
      return this.currentGame && this.currentGame.status === 'Started' && ((this.currentGame.home + this.currentGame.away) % this.switchOn === 0)
    },
    status () {
      return this.currentGame && this.currentGame.status
    },
    possesion () {
      return this.currentGame && this.currentGame.possesion
    },
    disabled () {
      return this.gameI > 0 && !this.match.games[this.gameI - 1].isFinal
    },
    matchParent () {
      const r = this.round
      return r && (this.poolId ? r.pools.find(f => f.id === this.poolId) : r.bracket)
    },
    matchOG () {
      return this.matchIn
      // const m = this.matchProps
      // return m && this.matchParent && this.matchParent.allMatches.find(f => f.id === m.id && f.number === m.n)
    },
    mDto () {
      return this.matchOG && this.matchOG.dto
    },
    lDto () {
      return this.liveMatch && this.liveMatch.match && this.liveMatch.match.dto
    },
    lDtoString () {
      return JSON.stringify(this.lDto)
    },
    dirty () {
      return this.oDto && this.oDto !== JSON.stringify(this.lDto)
    },
    homeLogo () {
      return this.homeTeam && this.useLogos && this.homeTeam.logox
    },
    awayLogo () {
      return this.awayTeam && this.useLogos && this.awayTeam.logox
    },
    homeTeam () {
      if (this.matchIn.homeTeam) return this.getTeam(this.matchIn.homeTeam.teamId)
      return null
    },
    awayTeam () {
      if (this.matchIn.awayTeam) return this.getTeam(this.matchIn.awayTeam.teamId)
      return null
    },
    key () {
      return this.matchProps && this.matchProps.key
    },
    path () {
      const obj = routeHelper(this.tournament, this.division, this.round, this.matchIn)
      const resolved = this.$router.resolve(obj.to)
      return resolved.href
    }
  },
  methods: {
    startTO () {
      this.showTO = true
    },
    closeMe (force) {
      if (this.dirty && !force) {
        this.window = 2
        return
      }
      this.$emit('close-click')
      this.reset()
    },
    reset () {
      this.window = 0
      this.gameI = this.matchIn.games.findIndex(f => !f.status || f.status.toLowerCase() !== 'complete')
      if (this.gameI === -1) this.gamei = this.matchIn.games.length - 1
      this.currentTab = null
      this.role = 'ref'
      this.showSwitch = false
      this.switchSides = false
      this.liveMatch = null
      this.oDto = null
      if (this.user) this.name = this.user.fullname
      if (this.isLiveScoring && this.matchProps && this.matchProps.id === this.matchIn.id) this.start()
    },
    finalize () {
      this.currentGame.isFinal = true
      if (this.gameI + 1 < this.match.games.length) {
        this.gameI += 1
      }
    },
    start () {
      this.liveMatch = {
        id: 0,
        guid: null,
        match: new Match(this.$VBL, JSON.stringify(this.matchOG), this.matchOG.type)
      }
      this.oDto = JSON.stringify(this.lDto)
      this.window = 1
      if (this.tournament.isLive) this.startSilent()
    },
    startSilent () {
      this.$VBL.liveScoring.start({ name: this.name, role: this.role, match: this.matchOG.liveStartDto, key: this.key })
        .then(r => {
          console.log('here')
          const m = this.liveMatch && this.liveMatch.match
          if (m) {
            m.id = r.data.id
            const games = r.data.match && r.data.match.games
            if (games && games.length > 0) {
              games.forEach(g => {
                const x = g.id && m.games.find(f => f.id === g.id)
                if (!x) {
                  const game = m.games.find(f => f.number === g.number)
                  if (game) {
                    game.id = g.id
                  } else {
                    console.log('no game')
                  }
                } else {
                  console.log('found x')
                }
              })
            } else {
              console.log('no games')
            }
          } else {
            console.log('no m')
          }
          this.commitToStore({
            id: r.data.id,
            path: this.path,
            key: r.data.key
          })
        })
        .catch(e => console.log(e.response))
    },
    commitToStore (dto) {
      this.$store.commit(mutations.SET_MATCH_PROPS, dto)
    },
    nextClick () {
      if (this.dirty) {
        this.saveEdit(false)
      }
      this.gameI++
    },
    saveClick () {
      if (this.currentGame && this.currentGame.status === 'Complete') {
        this.window = 2
        return
      }
      this.saveEdit(false)
    },
    saveEdit (close) {
      this.loading = true
      const mark = JSON.stringify(this.lDto)
      this.$VBL.post.matches([this.lDto])
        .then(r => {
          this.oDto = mark
        })
        .catch(err => console.log(err.response))
        .finally(() => {
          this.loading = false
          close && this.closeMe(true)
        })
    },
    preSaveSilent: debounce(function () {
      this.saveSilent()
    }, 400),
    saveSilent () {
      if (this.dirty && this.key) {
        console.log('saving')
        const mark = JSON.stringify(this.lDto)
        const apiCall = this.currentGame.status === 'Complete' ? this.saveMatch : this.saveScore
        apiCall()
          .then(r => {
            if (r.data) this.oDto = mark
          })
          .catch(err => console.log(err.response))
      }
    },
    saveMatch () {
      console.log('   match')
      return this.$VBL.post.matches([this.lDto], false, this.key)
    },
    saveScore () {
      console.log('   score')
      return this.$VBL.liveScoring.update({ id: this.currentGame.id, home: this.currentGame.home, away: this.currentGame.away }, this.key)
    },
    setName () {
      if (this.user) {
        this.name = this.user.fullname
      }
    }
  },
  watch: {
    'currentGame.home': function (n, o) {
      if (n > o) {
        this.currentGame.possesion = 'home'
      }
    },
    'currentGame.away': function (n, o) {
      if (n > o) {
        this.currentGame.possesion = 'away'
      }
    },
    switch: function (v) {
      this.showSwitch = v
    },
    switchSides () {
      this.showSwitch = false
    },
    window (v) {
      if (v === 0) {
        this.setName()
      }
    },
    active: 'reset',
    lDtoString: 'preSaveSilent'
  },
  mounted () {
    this.reset()
    this.setName()
  },
  components: {
    TeamCard,
    Countdown
  }
}
</script>

<style scoped>
.caption {
  font-size: xx-small !important;
}
.vs {
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
  width: 48px;
  height: 48px;
}
.vsHome {
  position: absolute;
  top: calc(50% - 48px - 36px);
  left: calc(50% - 24px);
  width: 48px;
  height: 48px;
}
.vsAway {
  position: absolute;
  top: calc(50% + 36px);
  left: calc(50% - 24px);
  width: 48px;
  height: 48px;
}
.vsHome2 {
  position: absolute;
  left: calc(50% - 48px - 36px);
  top: calc(50% - 48px);
  width: 48px;
  height: 48px;
}
.vsAway2 {
  position: absolute;
  left: calc(50% + 36px);
  top: calc(50% - 0px);
  width: 48px;
  height: 48px;
}
.fabs {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
